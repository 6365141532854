<template>
  <q-dialog v-model="dialogcontainer.openFileDialog">
    <q-card class="file-card" style="min-width: 1000px"> </q-card>
  </q-dialog>
</template>

<script lang="coffee">
  import { defineComponent, ref, onMounted, reactive, onUnmounted,defineAsyncComponent } from 'vue'
  import { Dialogcontainer  } from "./dialogcontainerCls.coffee"


  export default defineComponent({
    name: 'dialogcontainer',
    props:
      instancename:
        type: String
        default: 'dialogcontainer'
    setup: (props) ->
      dialogcontainer = reactive(new Dialogcontainer({props:props}))
      dialogcontainer.vueBinder()
      onMounted(() => dialogcontainer.mounted())
      onUnmounted(() => dialogcontainer.unmounted())
      return {dialogcontainer}

  })
</script>
